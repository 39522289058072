.background{
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--transparent-bg);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    width: 100vw;
    height: 100vh;

}