.question-answer{
    margin-left: 55px;
    display: flex;
    flex-direction: column;
    list-style: disc;
}
.questions-wrapper{
    margin-top: 20px;
}
.answer-list-wrapper{
    display: flex;
    margin: 10px 0;
    border-bottom: solid var(--light-gray) 1px; 
}
.answer-list-wrapper:last-child{
    border-bottom: none; 
}
.answer-list-wrapper > span{
    margin-right: 15px;
    color: gray;
}
.answer-list-wrapper > a{
    color:lightskyblue;
}
.new-answer{
    font-size: 7px;
    background-color: var(--light-green);
    color: #fff;
    padding: 5px 10px;
    border-radius: 20px; 
    margin: 0 10px;
    transform: scale(0.9);
}
.answer-wrapper{
    margin-left: 55px;
    display: flex;
    flex-direction: column;
    list-style: disc;
    margin-top: 20px;
}
.cls-1 {
    fill: var(--gray);
}  