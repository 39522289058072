.Category-list{
    margin: auto;
    margin-top: 50px;
}
.Category-list div.table h4.title{
    color: var(--blue);
    margin-bottom: 10px;
    text-transform: capitalize;
}
.Category-list div.table div.category-wrapper{
    padding: 5px 10px;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
}
div.category-wrapper .title{
    flex-grow: 3;
}
div.category-wrapper .show-answer,
div.category-wrapper .copy,
div.category-wrapper .questions-show,
div.category-wrapper .delete{
    flex-grow: 2;
    text-align: end;
}
div.category-wrapper .delete svg{
    fill: var(--gray);
}
a.btn:nth-child(3){
    margin-left: 10px;
}
.create-category{
    display: inline-block;
    margin-top: 30px;
}
.delete{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.category-wrapper .number{
    width: 20px;
}