
.Answer-wrapper{
    background-color: var(--light-gray);
}
.detail{
    padding: 10px 20px;
}
.answer-input{
    font-size: 17px;
    padding: 15px 10px;
    outline: none;
    border: solid 1px var(--dark-gray);

    width: 100%;
}
.btn-answer{
    margin-top: 10px;
}
.form-error{
    padding: 10px 20px;
    color: red;
}