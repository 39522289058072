nav{
    display: flex;
    justify-content: space-between;
    height: 90px;
    align-items: center;
    border-bottom: solid 1px var(--gray);
}
nav .icon{
    width: 50px; 
    height: 50px;
}
.logout{
    cursor: pointer;
}
#root > nav > div.right > div > svg{
    width: 50px;
    height: 50px;
    fill: var(--blue);
}
@media (max-width: 767px){
    #root > nav > div.right > div > svg{
        width: 25px;
        height: 25px;
    }
}