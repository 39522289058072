@font-face {
  font-family: Medium-OpenSans;
  src: url(./assets/fonts/OpenSans-Medium.ttf);
}
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
:root{
  --black: #0b0c01; 
  --light-dark: #1f2833;
  --gray: #c5c6c7;
  --blue: #66fcf1;
  --dark-blue: #45a29e;
  --transparent-bg: #ffffff1a;
}
body{
  font-family: Medium-OpenSans;
  color: var(--gray);
  margin: auto;
  background-color: var(--light-dark);
}
a {
  text-decoration: none;
  color: var(--blue);
}
.btn{
  display: inline-block;
  font-size: 13px;
  padding: 7px 30px;
  border: solid var(--blue) 1px;
  color: var(--gray);
  cursor: pointer;
  text-align: center;
  background-color: transparent;
}
hr{
  border: none;
  height: 1px;
  background: #e2dede;
}
.gradient-text {
  background: linear-gradient(45deg, #fe00f2, #fb0280);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.wrapper{
  max-width: 95%;
  margin: auto;
}
.create-question-wrapper form select,
input:focus,
#root > div > div.detail > form > input,
input{
  outline: none;
  border: solid 1px var(--blue);
  padding-left: 10px;
  width: 100% !important;
  height: 40px;
  width: 450px;
  color: var(--gray);
  background-color: transparent;
}