.category-detail{
    background-color: var(--light-gray);
}
.category-title{
    padding: 20px;
    background-color: var(--light-green);
    text-transform: capitalize;
    font-size: 20px;
}
.questions-wrapper{
    padding: 20px;
    text-transform: capitalize;
    
}
.questions-wrapper .question{
    display: flex;
}
.questions-wrapper .question .num{
    margin-right: 20px;
}
.delete-question{
    margin-left: 15px;
}