.question-answer{
    margin: 20px 55px;
}
.question-answer > div{
    display: flex;
}
.question-answer > div > div{
    margin-right: 10px;
}
.question-answer .answers{
    margin-left: 20px;
}