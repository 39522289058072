.set-user-wrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--light-gray);
}
.set-user-wrapper form{
    display: flex;
    flex-direction: column;
}
.set-user-wrapper form{
    text-transform: capitalize;
    color: var(--light-green);

}
#root > div > form > label > div{
    margin-bottom: 10px;
}
.set-user-wrapper form input{
    font-size: 17px;
    padding: 15px 10px;
    outline: none;
    border: solid 1px var(--blue);
    width: 100%;
    margin-bottom: 30px;
}
.set-user-wrapper form button{
    padding: 15px 0;
    background-color: var(--light-green);
    border: none;
    color: var(--light-dark);
    text-transform: uppercase;

}
#root > div > form > button{
    background-color: var(--dark-blue);
}
#root > div > div.btn.btn-answer{
    margin: 10px 20px;
}
#root > div > div.detail > form > div{
    margin-bottom: 15px;
}