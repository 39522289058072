.create-question-wrapper{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    background-color: var(--light-dark);
    z-index: 5;
    padding: 15px 30px;
    border-radius: 5px;
    text-align: center;
}
.create-question-wrapper .title{
    color: var(--blue);
    font-size: 25px;
    margin-bottom: 15px;
}
.create-question-wrapper form{
    display: flex;
    flex-direction: column;
}
.create-question-wrapper form select,
.create-question-wrapper form input{
    margin-bottom: 15px;
}
.create-question-wrapper form div.btn{
    width: 100px;
    margin: auto;
    margin-bottom: 10px;
}
a.btn{
    margin-right: 10px;
}