header{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
header .content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    border-radius: 25px;
    overflow: hidden;
}
header  h2{
    margin-bottom: 30px;
}
header  p {
    margin-bottom: 10px;
}
header  div{
    text-align: center;
}
header  .btn{
    margin-top: 30px;
    padding: 10px 40px;
} 

header .title{
    color: var(--blue);
}
.create-categroy-question  div:last-child{
    margin-left: 10px;
}